









































import Vue from 'vue'
import { BIcon, BIconChevronCompactLeft, BIconChevronCompactRight } from 'bootstrap-vue';
import { mapActions, mapGetters } from 'vuex';
import { MetaInfo } from 'vue-meta';
import authHelpers from '../../authHelpers';
import LoadingIndicator from '../../components/LoadingIndicator.vue';
export default Vue.extend({
  props: {
    albumSlug: {
      type: String,
      required: true,
    },
    photoId: {
      type: String,
      required: true,
    }
  },
  components: {
    BIcon,
    BIconChevronCompactLeft,
    BIconChevronCompactRight,
    LoadingIndicator
  },
  data: () => ({
    isAuthenticated: false,
  }),
  computed: {
    ...mapGetters('photos', [ 'currentPhoto', 'previousPhoto', 'nextPhoto' ]),
  },
  watch: {
    albumSlug: 'refreshAlbum',
    photoId: 'refreshPhoto',
  },
  async created() {
    this.isAuthenticated = await authHelpers.isAuthenticated();
    if (this.isAuthenticated) {
      await this.refreshAlbum();
    }
    await this.refreshPhoto();
  },
  methods: {
    ...mapActions('albums', [ 'loadAlbum' ]),
    ...mapActions('photos', [ 'loadPhoto', 'loadPhotosForAlbum' ]),

    async refreshAlbum() {
      await this.loadAlbum(this.albumSlug);
      await this.loadPhotosForAlbum(this.albumSlug);
    },

    async refreshPhoto() {
      await this.loadPhoto(this.photoId);
    }
  },
  metaInfo(this: any): MetaInfo {
    return {
      title: this.currentPhoto ? (this.currentPhoto.title || this.currentPhoto.fileName) : '',
    }
  },
});
